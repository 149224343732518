<template>
    <v-row justify="center" class="mt-16">
        <v-col cols="10" md="4">
            <v-card>
                <v-card-title>
                    {{ editing ? 'Wijzig account' : 'Maak Account aan' }}
                </v-card-title>
                <v-card-text>
                    <v-text-field id="input-1" outlined label="Gebruikersnaam" v-model="username"> </v-text-field>
                    <v-text-field id="input-2" outlined label="Wachtwoord" v-model="password"> </v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn id="send-form-button" block color="primary" @click="signup">
                        {{ editing ? 'Wijzig' : 'Maak Account aan' }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from '../../axios';

export default {
    data() {
        return {
            username: '',
            password: '',
            master: false,
            editToggle: false
        };
    },
    computed: {
        editing() {
            return !!this.$route.params.id;
        }
    },
    async mounted() {
        const id = this.$route.params.id;
        if (!id) {
            return;
        }

        const { data } = await axios.get(`/admin/${id}`);

        this.username = data.username;
        this.master = !!data.master;
        this.editToggle = true;
    },
    methods: {
        async signup() {
            if (!(this.username && this.password)) {
                alert('Vul alle gegevens in');
                return;
            }
            try {
                if (this.editToggle) {
                    await axios.put(`/admin/${this.$route.params.id}`, {
                        username: this.username,
                        password: this.password,
                        master: this.master
                    });
                } else {
                    await axios.post('/admin/signup', { username: this.username, password: this.password });
                }

                this.$router.replace('/admin');
            } catch (error) {
                console.error(error);
                alert('Account bestaat al!');
            }
        }
    },
    metaInfo: {
        title: 'Admin formulier'
    }
};
</script>
